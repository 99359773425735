<template>
  <v-container fluid>
    <v-list-item three-line class="pr-1">
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ formTitle }}
        </p>
        <v-list-item-subtitle>
          <v-toolbar flat color="white">
            <span style=" font-size: 18px; color: #03AC8C">{{
              $t("survey.titles.survey_title") + ": "
            }}</span>
            <span style="font-size: 16px" class="pl-2">
              {{ surveyModel.name ? surveyModel.name : "" }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              small
              tile
              outlined
              class="mr-2"
              color="gray"
              @click="cancelAddEditDialog"
            >
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              tile
              outlined
              class="mr-0"
              color="primary"
              @click="saveQuestionAction"
              :disabled="
                !valid1 ||
                  !getValidSectionCalc ||
                  !getCantValidPosivelAnswers(editedItemQuestion) ||
                  this.calcAnwersvalid
              "
            >
              {{ $t("common.buttons.save") }}
            </v-btn>
          </v-toolbar>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!--        Dialog Add Question-->
    <v-form ref="form" v-model="valid1">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $t("survey.titles.question_data") }}
              <v-spacer></v-spacer>
              <v-switch
                dense
                class="mr-4 pt-0"
                v-model="editedItemQuestion.isRequired"
                color="primary"
                :label="$t('survey.fields.isRequired')"
                hide-details
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <v-row class="ml-1 mr-1" v-if="editedItemQuestion.extra">
                <v-col cols="6">
                  <v-textarea
                      onkeypress="return (event.charCode != 34)" color="primary"
                      v-on:keyup.86="replaceCondicionalAnswer" v-on:keyup.ctrl.86="replaceCondicionalAnswer"
                      v-if="
                      editedItemQuestion.extraQuestionFatherRef
                        .questionStructureType === 'TEXT' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'LOTE' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'LACRE' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'QR'
                    "
                      v-model="editedItemQuestion.condicionalAnswer"
                      :label="$t('survey.titles.conditionalAnswer')"
                      :auto-grow="true"
                      :clearable="true"
                      :no-resize="true"
                      :row-height="40"
                      :rows="3"
                      :rules="requireRules"
                  />
                  <v-autocomplete :no-data-text="$t('common.fields.noDataAvailable')"
                    color="primary"
                    item-color="primary"
                    clearable
                    :items="
                      editedItemQuestion.extraQuestionFatherRef.possibleAnswer
                    "
                    :label="$t('survey.titles.conditionalAnswer')"
                    item-text="answer"
                    small-chips
                    item-value="answer"
                    v-model="editedItemQuestion.condicionalAnswer"
                    required
                    class="required"
                    :rules="requireRules"
                    v-if="
                      editedItemQuestion.extraQuestionFatherRef
                        .questionStructureType === 'PRODUCT' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'EQUIPMENT' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'OPERATOR' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'INPUT' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'MEASUREMENTUNIT' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'SEASON' ||
                        editedItemQuestion.extraQuestionFatherRef
                          .questionStructureType === 'LIST'
                    "
                  >
                    <template
                      v-slot:selection="{
                        item,
                        index,
                        attrs,
                        select,
                        selected,
                      }"
                    >
                      <v-chip small>
                        <span>{{ item.answer }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <v-currency-field
                      :default-value="null"
                      :value-as-integer="false"
                      :decimal-length="decimalFormat"
                      :auto-decimal-mode="false"
                      locale="en"
                      v-if="
                      editedItemQuestion.extraQuestionFatherRef
                        .questionStructureType === 'NUMBER'
                    "
                      color="primary"
                      :label="$t('survey.titles.conditionalAnswer')"
                      v-model="editedItemQuestion.condicionalAnswer"
                      :rules="requireRules"
                  >
                  </v-currency-field>
                </v-col>
              </v-row>
              <v-row class="ml-1 mr-1">
                <v-col cols="3">
                  <v-select
                    color="primary"
                    item-color="primary"
                    deletable-chips
                    v-model="editedItemQuestion.questionStructureType"
                    :label="$t('survey.fields.type')"
                    required
                    class="required"
                    item-value="value"
                    item-text="text"
                    :rules="requireRules"
                    :items="structureType"
                    small-chips
                    @change="changePosibleAnwers()"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceQuestionPunctuation" v-on:keyup.ctrl.86="replaceQuestionPunctuation"
                    color="primary"
                    type="text"
                    :rules="onlyDecimalFormat"
                    v-model="editedItemQuestion.questionPunctuation"
                    :label="$t('survey.fields.punctuation')"
                    @input="
                      calcLimitSection(editedItemQuestion.questionPunctuation)
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-alert
                    v-if="
                      editedItemQuestion.questionPunctuation > 0 &&
                        !getValidSectionCalc
                    "
                    border="bottom"
                    color="red"
                    dense
                    icon="mdi-account"
                    text
                    style="font-size: 12px"
                  >
                    {{
                      getLimitSectionErrorText(
                        editedItemQuestion.questionPunctuation
                      )
                    }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="ml-1 mr-1">
                <v-col cols="12">
                  <v-textarea
                   onkeypress="return (event.charCode != 34)" color="primary"
                   v-on:keyup.86="replaceText" v-on:keyup.ctrl.86="replaceText"
                    v-model="editedItemQuestion.questionHelp.text"
                    :label="$t('survey.fields.help')"
                    class="required"
                    :clearable="true"
                    :row-height="10"
                    :rows="5"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ $t("survey.titles.question") }}</v-card-title>
            <v-card-text>
              <v-row class="ml-1 mr-1">
                <v-col cols="12">
                  <v-textarea
                   onkeypress="return (event.charCode != 34)" color="primary"
                   v-on:keyup.86="replaceQuestion" v-on:keyup.ctrl.86="replaceQuestion"
                    v-model="editedItemQuestion.question"
                    :label="$t('survey.fields.question')"
                    required
                    :rules="requireRules"
                    class="required"
                    :clearable="true"
                    :row-height="10"
                    :rows="5"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row class="ml-1 mr-1">
                <v-col cols="12">
                  <v-combobox :no-data-text="$t('common.fields.noDataAvailable')"
                    v-model="possibleListAnswer"
                    small-chips
                    clearable
                    :label="$t('survey.fields.answer')"
                    color="primary"
                    multiple
                    v-if="editedItemQuestion.questionStructureType === 'LIST'"
                    required
                    class="required"
                    :rules="requireRules"
                    @change="changeListStructure()"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        small
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                        >{{ item }}
                      </v-chip>
                    </template>
                    <template v-slot:item="{ object, item, on, attrs }">
                      <div>{{ { name: item } }}</div>
                    </template>
                  </v-combobox>
                  <v-data-table
                    v-if="editedItemQuestion.questionStructureType === 'LIST'"
                    :headers="headersList"
                    :items="possibleListObjectAnswer"
                    v-model="possibleAnswer"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, false)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceAnwerPuntuation" v-on:keyup.ctrl.86="replaceAnwerPuntuation"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field> -->
                           <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                              single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="
                      editedItemQuestion.questionStructureType === 'PRODUCT'
                    "
                    :headers="headersProducts"
                    :items="products"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field>
                           <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                            single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="
                      editedItemQuestion.questionStructureType === 'EQUIPMENT'
                    "
                    :headers="headersEquipments"
                    :items="equipments"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    se
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field> -->
                           <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                            single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="
                      editedItemQuestion.questionStructureType === 'OPERATOR'
                    "
                    :headers="headersOperators"
                    :items="operators"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field> -->
                           <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                             :label="$t('survey.fields.note')"
                              single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="editedItemQuestion.questionStructureType === 'INPUT'"
                    :headers="headersInputs"
                    :items="inputs"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field> -->
                           <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                            single-line
                            v-model="props.item.anwerPuntuation"
                           >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="
                      editedItemQuestion.questionStructureType ===
                        'MEASUREMENTUNIT'
                    "
                    :headers="headersMeasurements"
                    :items="allUnits"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                          ></v-text-field> -->
                          <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                            single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>

                  <v-data-table
                    v-if="editedItemQuestion.questionStructureType === 'SEASON'"
                    :headers="headersSeassons"
                    :items="seasons"
                    v-model="possibleAnswer"
                    item-key="objectRelId"
                    show-select
                    class="elevation-1"
                    :footer-props="{
                      ...rowsPerPageItemsOptions,
                      itemsPerPageText: $t('common.table.itemsPerPageText'),
                      pageText:
                        '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
                    }"
                  >
                    <template v-slot:item.anwerPuntuation="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.anwerPuntuation"
                        @save="saveAnswerPuntuation(props.item, true)"
                      >
                        {{ props.item.anwerPuntuation }}
                        <template v-slot:input>
                          <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                            v-model="props.item.anwerPuntuation"
                            :rules="onlyDecimalFormat"
                            type="text"
                            :label="$t('survey.fields.note')"
                            single-line
                            color="primary"
                            @change=""
                          ></v-text-field> -->
                          <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            :allow-negative="false"
                            color="primary"
                            :label="$t('survey.fields.note')"
                            single-line
                            v-model="props.item.anwerPuntuation"
                          >
                          </v-currency-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>
                  <v-alert
                    v-if="this.calcAnwersvalid"
                    border="bottom"
                    color="red"
                    dense
                    icon="mdi-account"
                    text
                    style="font-size: 12px"
                    >{{ $t("survey.titles.limitQuestion") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import PermisionService from "../../../services/PermisionService";
import _ from "lodash";

export default {
  name: "Survey",
  data() {
    return {
      ready: false,
      profile: {},
      fileEditChange: false,
      editedIndexSection: -1,
      editedIndexSurvey: -1,
      editedIndexQuestion: -1,
      saveLoading: false,
      valid: false,
      valid1: false,
      calcSectionvalid: false,
      calcAnwersvalid: false,
      valid4: false,
      sectionSelected: {},
      surveyModel: {
        id: null,
        name: "",
        data: null,
        company: null,
        isTemplate: true,
        questionSectionList: [],
      },
      editedItemSection: this.getQuestionSectionModel(),
      editedItemQuestion: this.getQuestionModel(),
      possibleAnswer: [],
      possibleListAnswer: [],
      possibleListObjectAnswer: [],
      deleteQuestionStatus: false,
      deleteSectionStatus: false,
      deletingQuestionItem: {},
      deletingSectionItem: {},
      showDialog: false,
      max: 250,
      showDialogAddEditQuestion: false,
      showAddSectionDialog: false,
      showAddSurveyDialog: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5],
      },
      //Actions buttons
      fab: false,
    };
  },

  computed: {
    ...mapGetters({
      structureType: "details/detailsLocalEnum",
      authoritiesList: "authority/authorities",
      locationList: "storage/allStoragesByCompany",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      products: "product/products",
      operators: "operator/operators",
      equipments: "equipment/equipments",
      seasons: "season/seasons",
      allUnits: "units/allObjectUnits",
      inputs: "input/inputs",
      surveyEdit: "survey/surveyEdit",
      error: "survey/error",
      requireSize: "general/requireSize",
      languageStructure: "general/languageStructure",
      onlyDecimalFormat: "general/onlyDecimalFormat"
    }),
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    headersProducts: () => [
      { text: i18n.t("product.fields.name"), value: "product_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersEquipments: () => [
      { text: i18n.t("equipment.fields.name"), value: "equipment_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersOperators: () => [
      { text: i18n.t("operator.fields.name"), value: "operator_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersInputs: () => [
      { text: i18n.t("input.fields.name"), value: "input_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersMeasurements: () => [
      { text: i18n.t("measurementUnit.fields.name"), value: "unit_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersSeassons: () => [
      { text: i18n.t("season.fields.name"), value: "season_name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    headersList: () => [
      { text: i18n.t("survey.fields.answer"), value: "name" },
      { text: i18n.t("survey.fields.note"), value: "anwerPuntuation" },
    ],
    // Form condition title
    formTitle() {
      return this.editedIndexQuestion >= 0
        ? i18n.t("survey.titles.edit_question_title")
        : i18n.t("survey.titles.add_question_title");
    },
    getValidSectionCalc() {
      return this.calcSectionvalid;
    },
  },

  created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListProduct: "product/fetchListProduct",
      fetchListOperator: "operator/fetchListOperator",
      fetchListEquipment: "equipment/fetchListEquipment",
      fetchListInputs: "input/fetchListInputs",
      fetchListSeasons: "season/fetchListSeasons",
      saveSurvey: "survey/saveSurvey",
      saveSection: "survey/saveSection",
      saveQuestion: "survey/saveQuestion",
      deleteQuestion: "survey/deleteQuestion",
      deleteQuestionWithDependents: "survey/deleteQuestionWithDependents",
      deleteQuestionSection: "survey/deleteQuestionSection",
      fetchSectionById: "survey/fetchSectionById",
      change_state: "survey/change_state",
      fetchListAuthority: "authority/fetchListAuthority",
      fetchAllUnits: "units/fetchAllUnits",
      errorOFF: "survey/errorOFF",
      setProductList: "product/setProductList",
      setInputsList: "input/setInputsList",
      setUnitsList: "units/setUnitsList",
      setSeasonList: "season/setSeasonList",
      setEquipmentList: "equipment/setEquipmentList",
      setOperatorList: "operator/setOperatorList",
    }),

    async initialize() {
      this.ready = false;
      let question = JSON.parse(localStorage.getItem("question"));
      this.sectionSelected.id = JSON.parse(localStorage.getItem("section"));
      if (question) {
        this.editedIndexQuestion = question;
      } else {
        this.editedIndexQuestion = -1;
      }
      try {
        await this.loadDependencies();
      } finally {
        if (this.surveyModel.id > 0) {
          this.surveyModel = {};
          this.surveyModel = Object.assign({}, this.surveyEdit);
          localStorage.setItem("survey", JSON.stringify(this.surveyModel));
          if (
            this.surveyModel.questionSectionList &&
            this.surveyModel.questionSectionList.length === 0
          ) {
            this.showAddSectionDialog = true;
          }
        }
        this.ready = true;
      }
    },

    loadDependencies() {
      const promises = [];
      // add here a list of promises;
      this.profile = JSON.parse(localStorage.getItem("profile"));
      promises.push(this.fetchListProduct([this.profile, this.$toast]));
      promises.push(this.fetchListOperator([this.profile, this.$toast]));
      promises.push(this.fetchListEquipment([this.profile, this.$toast]));
      promises.push(this.fetchListInputs([this.profile, this.$toast]));
      promises.push(this.fetchListSeasons([this.profile, this.$toast]));
      promises.push(
        this.fetchSectionById([this.sectionSelected.id, this.$toast])
      );
      promises.push(this.fetchAllUnits(this.$toast));
      return Promise.all(promises);
    },

    changeOrder(targetSection, targetQuestions) {
      this.order = true;
      this.surveyModel.questionSectionList = _.map(
        this.surveyModel.questionSectionList,
        (section, indexSection) => {
          return {
            ...section,
            orderCriteria: indexSection + 1,
            questions:
              targetSection && targetSection.id === section.id
                ? targetQuestions
                : _.map(section.questions, (question, index) => {
                    return {
                      ...question,
                      orderCriteria: index + 1,
                    };
                  }),
          };
        }
      );
    },

    check(permisions) {
      return PermisionService.check(permisions);
    },

    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },

    dialogClose() {
      this.showDialog = false;
    },

    // Accion de los modales de Borrar para secciones y preguntas
    deleteItem() {
      if (this.deleteSectionStatus === true) {
        this.deleteSectionStatus = false;
        let index = this.surveyModel.questionSectionList.indexOf(
          this.deletingSectionItem
        );
        this.deleteQuestionSection([
          this.deletingSectionItem.id,
          this.$toast,
        ]).finally(() => {
          this.surveyEditRefresh();
        });
      } else if (this.deleteQuestionStatus === true) {
        this.deleteQuestionStatus = false;
        this.deleteQuestion([
          this.deletingQuestionItem.id,
          this.$toast,
        ]).finally(() => {
          this.surveyEditRefresh();
        });
      }
      this.dialogClose();
    },

    // SURVEY
    saveSurveyAction() {
      this.saveLoading = true;
      this.surveyModel.company = { id: this.profile.company_id };
      this.saveSurvey([
        this.surveyModel,
        this.editedIndexSurvey,
        null,
        null,
        this.$toast,
      ]).then(() => {
        if (!this.error) {
          this.surveyModel.id = this.surveyEdit.id;
          this.editedIndexSurvey = this.surveyModel.id;
          this.showAddSurveyDialog = false;
          this.surveyEditRefresh();
        } else {
          this.errorOFF();
          this.$router.push({ name: "survey" });
        }

        this.saveLoading = false;
      });
    },

    cancelSurvey() {
      if (this.editedIndexSurvey < 0) {
        this.$refs.form5.reset();
        this.$router.push({ name: "survey" });
      } else {
        this.showAddSurveyDialog = false;
      }
    },

    surveyEditRefresh() {
      if (this.surveyModel.id) {
        this.fetchSurveyById([this.surveyModel.id, this.$toast]).finally(() => {
          this.surveyModel = {};
          this.surveyModel = Object.assign({}, this.surveyEdit);
          if (
            this.surveyModel.questionSectionList &&
            this.surveyModel.questionSectionList.length === 0
          ) {
            this.showAddSectionDialog = true;
          }
          localStorage.setItem("survey", JSON.stringify(this.surveyModel));
        });
      }
      this.order = false;
    },

    // SECTION QUESTIONS
    limitSection() {
      let calc = 0;
      _.forEach(this.editedItemSection.questions, (question) => {
        calc += question.questionPunctuation;
        calc += _.sumBy(question.extraQuestions, "questionPunctuation");
      });
      this.calcSectionvalid =
        calc <= parseInt(this.editedItemSection.punctuation, 10);
      return calc;
    },

    getSectionErrorText() {
      return (
        i18n.t("survey.titles.punctuationSection") +
        this.limitSection() +
        " > " +
        this.editedItemSection.punctuation +
        " " +
        i18n.t("survey.titles.punctuationSection2")
      );
    },

    getQuestionSectionModel() {
      return {
        name: null,
        questions: [],
        responsables: [],
        locations: [],
        punctuation: null,
        orderCriteria: 0,
      };
    },

    sectionPunctuation(sectionId) {
      let calc = 0;
      _.forEach(
        _.find(this.surveyModel.questionSectionList, { id: sectionId })
          .questions,
        (question) => {
          calc += question.questionPunctuation;
          calc += _.sumBy(question.extraQuestions, "questionPunctuation");
        }
      );
      return parseInt(calc, 10);
    },

    openAddSectionDialog(section) {
      this.editedItemSection = section
        ? Object.assign({}, section)
        : this.getQuestionSectionModel();
      this.editedIndexSection = section ? this.editedItemSection.id : -1;
      this.showAddSectionDialog = true;
    },

    cancelAddSection() {
      this.showAddSectionDialog = false;
      this.$refs.form4.reset();
      this.editedItemSection = this.getQuestionSectionModel();
    },

    deleteSectionDialog(sectionValues) {
      this.deleteSectionStatus = true;
      this.deletingSectionItem = sectionValues;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },

    saveSectionAction() {
      let item = Object.assign({}, this.editedItemSection);

      item.company = { id: this.profile.company_id };
      if (this.surveyModel.hasOwnProperty("id"))
        item.surveyId = this.surveyModel.id;
      this.showAddSectionDialog = false;
      item.orderCriteria =
        this.editedIndexSection >= 0
          ? item.orderCriteria
          : this.surveyModel.questionSectionList.length > 0
          ? _.maxBy(this.surveyModel.questionSectionList, "orderCriteria")
              .orderCriteria + 1
          : 1;
      this.saveSection([item, this.editedIndexSection, this.$toast]).finally(
        () => {
          this.surveyEditRefresh();
          this.cancelAddSection();
        }
      );
    },

    // QUESTIONS
    extraQuestion(item, sectionValue) {
      this.editedItemQuestion.extra = true;
      this.editedItemQuestion.extraQuestionFatherRef = item;
      this.editedItemQuestion.questionSectionId = sectionValue.id;
      this.sectionSelected = sectionValue;
      this.showDialogAddEditQuestion = true;
    },

    async editItem(item, sectionValue, extra) {
      this.changePosibleAnwers();
      this.fileEditChange = false;
      this.editedItemQuestion = this.getQuestionModel();
      this.editedIndexQuestion = extra
        ? this.expandedExtraQuestions[
            sectionValue.id
          ][0].extraQuestions.indexOf(item)
        : sectionValue.questions.indexOf(item);
      this.sectionSelected = sectionValue;
      this.editedItemQuestion = Object.assign({}, item);
      this.editedItemQuestion.extra = extra;
      this.editedItemQuestion.extraQuestionFatherRef = _.find(
        sectionValue.questions,
        { id: this.editedItemQuestion.extraQuestionFather }
      );
      if (!this.editedItemQuestion.questionHelp) {
        this.editedItemQuestion.questionHelp = { text: "" };
      }
      if (this.editedItemQuestion.possibleAnswer.length > 0) {
        this.possibleAnswer = _.map(
          this.editedItemQuestion.possibleAnswer,
          (possibleAnswer) => {
            this.possibleListAnswer.push(possibleAnswer.answer);
            return {
              ...possibleAnswer,
              anwerPuntuation: possibleAnswer.punctuation,
              name: possibleAnswer.answer,
            };
          }
        );
        if (this.editedItemQuestion.questionStructureType === "LIST") {
          let newPosibleAnswer = [];
          _.forEach(this.possibleListAnswer, (object, key) => {
            const posibleAnsW = _.find(this.possibleAnswer, { name: object });
            newPosibleAnswer.push(posibleAnsW);
          });
          this.possibleListObjectAnswer = newPosibleAnswer;
        }
      }
      this.editedItemQuestion.questionSectionId = sectionValue.id;
      this.setObjectStructurePuntuation();
      this.showDialogAddEditQuestion = true;
    },

    setObjectStructurePuntuation() {
      switch (this.editedItemQuestion.questionStructureType) {
        case "OPERATOR":
          this.setOperatorList(this.getMapObjct(this.operators));
          break;
        case "EQUIPMENT":
          this.setEquipmentList(this.getMapObjct(this.equipments));
          break;
        case "SEASON":
          this.setSeasonList(this.getMapObjct(this.seasons));
          break;
        case "MEASUREMENTUNIT":
          this.setUnitsList(this.getMapObjct(this.allUnits));
          break;
        case "INPUT":
          this.setInputsList(this.getMapObjct(this.inputs));
          break;
        case "PRODUCT":
          this.setProductList(this.getMapObjct(this.products));
          break;
      }
    },

    getMapObjct(targetList) {
      return _.map(targetList, (item) => {
        const found = _.find(this.possibleAnswer, { objectRelId: item.id });
        return {
          ...item,
          anwerPuntuation: found ? found.anwerPuntuation : null,
          objectRelId: item.id,
        };
      });
    },

    getObjectStructureToSave(questionStructureType, objectRelId) {
      let object = null;
      switch (questionStructureType) {
        case "OPERATOR":
          object = this.getMapObjectToSave(this.operators, objectRelId);
          return object ? object.languageKey : "";
        case "EQUIPMENT":
          object = this.getMapObjectToSave(this.equipments, objectRelId);
          return object ? object.languageKey : "";
        case "SEASON":
          object = this.getMapObjectToSave(this.seasons, objectRelId);
          return object ? object.languageKey : "";
        case "MEASUREMENTUNIT":
          object = this.getMapObjectToSave(this.allUnits, objectRelId);
          return object ? object.languageKey : "";
        case "INPUT":
          object = this.getMapObjectToSave(this.inputs, objectRelId);
          return object ? object.languageKey : "";
        case "PRODUCT":
          object = this.getMapObjectToSave(this.products, objectRelId);
          return object ? object.language_key : "";
      }
    },

    getMapObjectToSave(objects, id) {
      return _.find(objects, { id: id });
    },

    getQuestionModel() {
      return {
        question: null,
        questionStructureType: null,
        questionHelp: {},
        answer: null,
        isRequired: false,
        isInformative: false,
        questionPunctuation: null,
        possibleAnswer: [],
        questionSectionId: null,
        orderCriteria: 0,
        company: null,
        // extra question
        extra: false,
        extraQuestionFatherRef: null,
        extraQuestionFather: null,
        extraQuestions: [],
        condicionalAnswer: null,
      };
    },

    cancelAddEditDialog() {
      this.$refs.form.reset();
      this.showDialogAddEditQuestion = false;
      this.editedItemQuestion = this.getQuestionModel();
      this.editedIndexQuestion = -1;
    },

    addQuestion(sectionValues) {
      console.log("addQuestion");
      this.sectionSelected = Object.assign({}, sectionValues);
      this.showDialogAddEditQuestion = true;
    },

    saveQuestionAction() {
      let question = Object.assign({}, this.editedItemQuestion);
      if (
        !_.keys(question.questionHelp).length > 0 ||
        !question.questionHelp.text ||
        question.questionHelp.text === ""
      ) {
        question.questionHelp = null;
      }
      question.possibleAnswer = [];
      if (this.possibleAnswer.length > 0) {
        _.forEach(this.possibleAnswer, (possibleAnswer) => {
          question.possibleAnswer.push({
            id: this.editedIndexQuestion >= 0 ? possibleAnswer.id : null,
            objectRelId:
              question.questionStructureType !== "LIST"
                ? possibleAnswer.objectRelId
                : 0,
            answer:
              question.questionStructureType === "LIST"
                ? possibleAnswer.name
                : this.getObjectStructureToSave(
                    question.questionStructureType,
                    possibleAnswer.objectRelId
                  ),
            punctuation: parseInt(possibleAnswer.anwerPuntuation, 10),
          });
        });
      }
      question.answer = this.languageStructure;
      question.isInformative = question.questionPunctuation <= 0;
      question.questionPunctuation = question.questionPunctuation
        ? question.questionPunctuation
        : 0;
      question.questionPunctuation = parseInt(question.questionPunctuation, 10);
      question.company = { id: this.profile.company_id };
      question.questionSectionId = this.sectionSelected.id;
      question.orderCriteria =
        this.editedIndexQuestion >= 0
          ? question.orderCriteria
          : this.sectionSelected.questions.length > 0
          ? _.maxBy(this.sectionSelected.questions, "orderCriteria")
              .orderCriteria + 1
          : 1;
      this.saveQuestion([
        question,
        this.editedIndexQuestion,
        this.$toast,
      ]).finally(() => {
        this.surveyEditRefresh();
      });
      this.cancelAddEditDialog();
    },

    deleteQuestionDialog(item, sectionValues) {
      this.deleteQuestionStatus = true;
      this.deletingQuestionItem = item;
      this.deletingSectionItem = sectionValues;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },

    changedFile() {
      if (this.editedIndexQuestion >= 0) {
        this.fileEditChange = true;
      }
    },

    // ANSWER PUNTUATION
    saveAnswerPuntuation(item, objects) {
      if (objects) {
        let found = false;
        _.forEach(this.possibleAnswer, (object, key) => {
          if (object.objectRelId === item.id) {
            this.possibleAnswer[key].anwerPuntuation = item.anwerPuntuation;
            found = true;
          }
        });
        if (!found) {
          this.possibleAnswer.push(item);
        }
      } else {
        let found = false;
        _.forEach(this.possibleAnswer, (object, key) => {
          if (object.name === item.name) {
            this.possibleAnswer[key] = item;
            found = true;
          }
        });
        if (!found) {
          this.possibleAnswer.push({ name: item, anwerPuntuation: null });
        }
      }
      this.getValidPosivelAnswers();
    },

    changePosibleAnwers() {
      this.possibleAnswer = [];
      this.possibleListAnswer = [];
      this.possibleListObjectAnswer = [];
    },

    changeListStructure() {
      let newPosibleAnswer = [];
      _.forEach(this.possibleListAnswer, (object, key) => {
        const posibleAnsW = _.find(this.possibleAnswer, { name: object });
        if (posibleAnsW) {
          newPosibleAnswer.push(posibleAnsW);
        } else {
          newPosibleAnswer.push({ name: object, anwerPuntuation: null });
        }
      });
      this.possibleAnswer = newPosibleAnswer;
      this.possibleListObjectAnswer = newPosibleAnswer;
    },

    calcLimitSection(currentItem) {
      let calc = 0;
      _.forEach(this.sectionSelected.questions, (question) => {
        calc += question.questionPunctuation;
        calc += _.sumBy(question.extraQuestions, "questionPunctuation");
      });
      if (currentItem) {
        if (this.editedIndexQuestion >= 0) {
          let targetQuestion = _.find(this.sectionSelected.questions, {
            id: this.editedItemQuestion.id,
          });
          if (!targetQuestion) {
            _.forEach(this.sectionSelected.questions, (question) => {
              let temTarget = null;
              temTarget = _.find(question.extraQuestions, {
                id: this.editedItemQuestion.id,
              });
              if (temTarget) {
                targetQuestion = temTarget;
              }
            });
          }
          if (
            targetQuestion &&
            parseInt(targetQuestion.questionPunctuation, 10) !==
              this.editedItemQuestion.questionPunctuation
          ) {
            const sectionValue =
              parseInt(calc, 10) +
              parseInt(currentItem, 10) -
              parseInt(targetQuestion.questionPunctuation, 10);
            this.calcSectionvalid =
              sectionValue <= parseInt(this.sectionSelected.punctuation, 10);
            this.getValidPosivelAnswers();
            return sectionValue;
          } else {
            this.calcSectionvalid = true;
          }
        } else {
          const sectionValue = parseInt(calc, 10) + parseInt(currentItem, 10);
          this.calcSectionvalid =
            sectionValue <= parseInt(this.sectionSelected.punctuation, 10);
          this.getValidPosivelAnswers();
          return sectionValue;
        }
      }
    },

    getLimitSectionErrorText(currentItem) {
      return currentItem > 0
        ? i18n.t("survey.titles.punctuationSection") +
            this.calcLimitSection(currentItem) +
            " > " +
            this.sectionSelected.punctuation +
            " " +
            i18n.t("survey.titles.punctuationSection2")
        : "";
    },

    getCantValidPosivelAnswers(question) {
      if (question.questionStructureType) {
        if (
          question.questionStructureType !== "TEXT" &&
          question.questionStructureType !== "NUMBER" &&
          question.questionStructureType !== "LOTE"
        ) {
          return this.possibleAnswer.length > 0;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    getValidPosivelAnswers() {
      if (
        this.editedItemQuestion.questionPunctuation &&
        parseInt(this.editedItemQuestion.questionPunctuation, 10) > 0
      ) {
        const outLimitPuntuation = _.filter(this.possibleAnswer, (answer) => {
          return (
            parseInt(answer.anwerPuntuation, 10) >
            parseInt(this.editedItemQuestion.questionPunctuation, 10)
          );
        });
        this.calcAnwersvalid =
          outLimitPuntuation && outLimitPuntuation.length > 0;
      }
    },

    back() {
      localStorage.removeItem("survey");
      this.$router.push({ name: "survey" });
    },

    publish() {
      const changeStateDTO = {
        surveyStateType: "PUBLISHED",
        surveyId: this.surveyModel.id,
      };
      this.change_state([changeStateDTO, this.$toast]).finally(() => {
        this.back();
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCondicionalAnswer(event){
      this.editedItemQuestion.condicionalAnswer =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceQuestionPunctuation(event){
      this.editedItemQuestion.questionPunctuation =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceText(event){
      this.editedItemQuestion.questionHelp.text =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceQuestion(event){
      this.editedItemQuestion.question =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
